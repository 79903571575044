<template>
  <div id="app">
    <!-- 检修记录 -->
    <el-row>
      <el-col :span="6" class="rows">
        <el-select v-model="deviceType" placeholder="请选择设备类型" clearable>
          <el-option v-for="item in deviceTypeList" :key="item.id" :label="item.name" :value="item.name" @click.native="selectOptions(item)">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="rows" style="margin-left:-370px">
        <el-button type="primary" @click="queryInfo">查询</el-button>
      </el-col>
      <el-col :span="6" class="rows" style="float:right;width:10%;margin:10px 0">
        <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
          <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title">
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-table :stripe="true" :data="abnormalDeviceList">
      <el-table-column v-if="tableTitle.includes('设备编号')" label="设备编号" prop="deviceId" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('设备类型')" label="设备类型" prop="deviceTypeName" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('单位')" label="单位" prop="ciId" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('报修类型')" label="报修类型" prop="repairTypeId" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('备注')" label="备注" prop="xjNote" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('时间')" label="时间" prop="createTime" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="300">
        <template slot-scope="scope">
          <el-button type="success" size="small" @click="completeImage(scope.row)">完成图片</el-button>
          <el-button type="success" size="small" @click="reportImage(scope.row)">上报图片</el-button>
          <el-button type="primary" size="small" @click="checkMore(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- :title="rowInfo.repairTypeId" -->
    <el-dialog title="检修设备信息" :visible.sync="dialogVisible" width="45%" style="background:rgba(0,0,0,0.5)">
      <div>
        <el-descriptions class="margin-top" :column="2" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              上报人姓名
            </template>
            {{rowInfo.xjName}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              上报人电话
            </template>
            {{rowInfo.xjPhone}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-edit-outline"></i>
              上报人备注
            </template>
            {{rowInfo.xjNote}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              主管姓名
            </template>
            {{rowInfo.zgName}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-tickets"></i>
              主管意见
            </template>
            <el-tag effect="dark" size="small" :type="rowInfo.zgBingo=='1'?'success':'danger'">{{rowInfo.zgBingo=='1'?'同意':'不同意'}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-edit-outline"></i>
              主管备注
            </template>
            {{rowInfo.zgNote}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              主管电话
            </template>
            {{rowInfo.zgPhone}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-time"></i>
              主管审核时间
            </template>
            {{rowInfo.zgTime}}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="'问题图片上报人：'+rowInfo.xjName" :visible.sync="showImage" width="30%" style="background:rgba(0,0,0,0.5)">
      <div style="height:280px;">
        <el-image style="height:75%;margin:10px" :src="url" :preview-src-list="urlList" v-for="(item ,index) in urlList" :key="index" title="点击查看大图">
        </el-image>
      </div>
    </el-dialog>

    <el-dialog :title="'完成图片上报人：'+rowInfo1.xjName" :visible.sync="showImage1" width="30%" style="background:rgba(0,0,0,0.5)">
      <div style="height:280px;">
        <el-image style="height:75%;margin:10px" :src="item" :preview-src-list="urlList1" v-for="(item ,index) in urlList1" :key="index" title="点击查看大图">
        </el-image>
      </div>
    </el-dialog>

    <!-- <el-dialog :title="'完成图片上报人：'+ rowInfo1.xjName" :visible.sync="showImage1" width="40%" style="background:rgba(0,0,0,0.5)">
      <div style="height:380px;width:100%;">
        <el-image style="height:50%;margin:5px;" :src="item" :preview-src-list="urlList1" v-for="(item,index) in urlList1" :key="index" title="点击查看大图">
        </el-image>
      </div>
    </el-dialog> -->

    <!-- 分页 -->
    <div>
      <el-pagination :current-page.sync="current" :page-size="limit" :total="total" layout="total, prev, pager, next, jumper" @current-change="handleCurrentChange" class="pagination"></el-pagination>
    </div>
  </div>
</template>

<script>

import deviceInfo from '@/api/facilitiesManagement/DeviceInfo'
import companySystem from "@/api/managementApi/CompanyInfo";
import baseData from "@/assets/config/BaseData";

export default {
  name: "MaintenanceRecord",
  data() {
    return {
      tableTitleList: [{
        id: 0,
        title: '设备编号',
      }, {
        id: 2,
        title: '设备类型',
      }, {
        id: 3,
        title: '单位',
      }, {
        id: 4,
        title: '报修时间',
      }, {
        id: 5,
        title: '备注',
      }, {
        id: 6,
        title: '时间',
      }],
      tableTitle: ['设备编号', '设备类型', '单位', '报修时间', '备注', '时间'],

      baseUrl: baseData.wechatName,
      url: '',
      srcList: [],
      urlList: [],
      url1: '',
      srcList1: [],
      urlList1: [],
      // 查看更多
      dialogVisible: false,
      showImage: false,
      showImage1: false,
      // 每行信息
      rowInfo1: [],
      rowInfo: [],
      deviceType: '',
      // 设备类型
      deviceTypeList: [],

      // 页码
      limit: 10,
      current: 1,
      total: 1,

      // 所有公司
      companyInfoList: [],

      // 异常设备
      abnormalDeviceList: [],
    };
  },
  created() {
    // 查询公司
    this.getCompanyInfo()

    //   查检修记录
    this.getMaintenanceRecordList(this.current, this.limit)

    // 设备类型
    this.getDeviceManagementType()

  },


  methods: {
    selectTitle(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle.push('设备编号')
      } else {
        this.tableTitle = value
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.getMaintenanceRecordList(this.current, this.limit);
    },

    // 所有公司
    getCompanyInfo() {
      companySystem.getCompanyInfo().then((res) => {
        this.companyInfoList = res.data.data.list;
      });
    },

    // 查询检修记录
    getMaintenanceRecordList() {
      deviceInfo.getAllOverhaulHistory(this.current, this.limit, this.deviceType).then(res => {
        this.total = res.data.data.total;
        this.abnormalDeviceList = res.data.data.deviceManagementReportQuestionOkList;
        if (res.message == "无检修记录") {
          this.abnormalDeviceList = []
        } else {
          for (let i = 0; i < this.abnormalDeviceList.length; i++) {
            for (let j = 0; j < this.companyInfoList.length; j++) {
              if (this.abnormalDeviceList[i].ciId == this.companyInfoList[j].id) {
                this.abnormalDeviceList[i].ciId = this.companyInfoList[j].name;
              }
            }
            //检修记录图片
            this.url = baseData.wechatName + this.abnormalDeviceList[i].imageUrlList[0];
            for (let k = 0; k < this.abnormalDeviceList[i].imageUrlList.length; k++) {
              this.srcList.push(baseData.wechatName + this.abnormalDeviceList[i].imageUrlList[k])
            }
          }

        }
      })
    },

    //点击查询按钮
    queryInfo() {
      this.abnormalDeviceList = []
      this.getMaintenanceRecordList();
    },

    // 设备类型
    getDeviceManagementType() {
      deviceInfo.getDeviceManagementType().then(res => {
        this.deviceTypeList = res.data.data.list;
      })
    },

    selectOptions(item) {
      this.deviceType = item.name;
    },

    // 查看更多
    checkMore(row) {
      console.log(row);
      this.rowInfo = row;
      this.dialogVisible = true;
    },
    // 上报图片
    reportImage(row) {
      console.log(row);
      this.url = this.baseUrl + row.imageUrlList[0];
      this.rowInfo = row;
      this.showImage = true;
      this.urlList = [];
      for (let i = 0; i < row.imageUrlList.length; i++) {
        this.urlList.push(this.baseUrl + row.imageUrlList[i])
      }
    },
    // 完成图片
    completeImage(row) {
      console.log(row);
      this.url1 = this.baseUrl + row.checkedImageUrlList[0];
      this.rowInfo1 = row;
      this.showImage1 = true;
      this.urlList1 = [];
      for (let i = 0; i < row.checkedImageUrlList.length; i++) {
        this.urlList1.push(this.baseUrl + row.checkedImageUrlList[i])
      }

    }
  },
};
</script>

<style lang="scss" scoped>
.rows {
  margin: 10px 10px 10px 0;
}
/deep/.el-dialog__body {
  padding: 20px 20px;
}
</style>